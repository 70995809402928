interface MailTemplateEntities {
  leagueName: string;
  leagueMatchCount: number;
  firstProfileLink: string;
  firstFullName: string;
  secondProfileLink: string;
  secondFullName: string;
  thirdProfileLink: string;
  thirdFullName: string;
}

export const getMailTemplate = (data: MailTemplateEntities): string => {
  return `
  <div>
  <h1>${data.leagueName}</h1>
  <p>📍&nbsp;Yeni sezon yeni hafta ile 🏁 devam ediyor.</p>
  <p>🔖&nbsp;Yeni haftanın rezervasyonları açıldı.</p>
  <br>
  <p>${data.leagueName}nde toplam ${data.leagueMatchCount} maç oynandı.</p>
  <br>
  <p>Bu hafta tenis liginde;</p>
  <p>🌟&nbsp;Ligde 1’likte <a href="${data.firstProfileLink}" target="_blank">${data.firstFullName}</a> yer aldı.</p>
  <p>🌟&nbsp;Ligde 2’likte <a href="${data.secondProfileLink}" target="_blank">${data.secondFullName}</a> yer aldı.</p>
  <p>🌟&nbsp;Ligde 3’likte <a href="${data.thirdProfileLink}" target="_blank">${data.thirdFullName}</a> yer aldı.</p>
  <br>
  <p>🏆&nbsp;Ödüller: <a href="https://tenis.io/oduller" target="_blank">https://tenis.io/oduller</a></p>
  <br>
  <p>📈&nbsp;Yendiğiniz de rakibinizin güç (etken) puanı kadar kazanırsınız. Yenilen +5 puan alır.</p>
  <p>📋&nbsp;Kurallar: <a href="https://bit.ly/3EwUlcW" target="_blank">https://bit.ly/3EwUlcW</a></p>
  <br>
  <p>⚠️&nbsp;Lütfen maçın tarihine dikkat edin, farklı tarihleri alan tenisçiler bulunmaktadır.</p>
  <br>
  <p>❓&nbsp;Nasıl üye olurum: <a href="https://bit.ly/3GtXKJL" target="_blank">https://bit.ly/3GtXKJL</a></p>
  <p>🎫&nbsp;Maç Oynama Süreci: <a href="https://bit.ly/3snwdo8" target="_blank">https://bit.ly/3snwdo8</a></p>
  <p>🏆&nbsp;Ödüller: <a href="https://tenis.io/oduller" target="_blank">https://tenis.io/oduller</a></p>
  <br>
  <p><a href="https://tenis.io" target="_blank">Tenis.io</a> uygulamasını yükle;</p>
  <p>📲&nbsp;android: <a href="https://bit.ly/32oIVJX" target="_blank">https://bit.ly/32oIVJX</a></p>
  <p>📱&nbsp;iphone : <a href="https://apple.co/3CNuifT" target="_blank">https://apple.co/3CNuifT</a></p>
  <p>🌐&nbsp;Web'den <a href="https://tenis.io" target="_blank">https://tenis.io</a></p>
  <br>
  <p>${data.leagueName} Yönetimi</p>
</div>
  `;
};
