import { Injectable } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask
} from '@angular/fire/compat/storage';

import 'firebase/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SharedModule
})
export class FireStorageService {
  constructor(private storage: AngularFireStorage) {}

  uploadStorage(file, filePath): AngularFireUploadTask {
    return this.storage.upload(filePath, file);
  }

  getFileRef(filePath): AngularFireStorageReference {
    return this.storage.ref(filePath);
  }

  deleteFromURL(url): Observable<any> {
    return this.storage.refFromURL(url).delete();
  }
}
