import { Injectable } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SharedModule
})
export class MailService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  sendMail(data): Observable<void> {
    return this.http.post<any>(`${this.apiUrl}mail/send-mail`, data);
  }
}
