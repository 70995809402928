import { environment } from '@env/environment';
import { DEV_URL, PROD_URL } from '@shared/variables/constant.variable';

export const convertTitleToUrl = (prefix: string, title = '', id: string) => {
  const baseURL = environment.production ? PROD_URL : DEV_URL;
  return (
    baseURL +
    '/' +
    prefix +
    '/' +
    title
      .replace('Ğ', 'g')
      .replace('Ü', 'u')
      .replace('Ş', 's')
      .replace('I', 'i')
      .replace('İ', 'i')
      .replace('Ö', 'o')
      .replace('Ç', 'c')
      .replace('ğ', 'g')
      .replace('ü', 'u')
      .replace('ş', 's')
      .replace('ı', 'i')
      .replace('ö', 'o')
      .replace('ç', 'c')
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '') +
    `__${id}`
  );
};
